import React from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getSpreadSheets } from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  getProfitLossData,
  resetData,
} from "../../../../redux/ProfitandLoss/ProfitandLossSlice";
import Loading from "../../../../components/LoadingIndicator/Loading";
import _ from "lodash";
import DownloadModal from "./DownloadModal";
import { ButtonComponent } from "../../../../components/Button/Button";
import { reset } from "../../../../redux/CustomReport/CustomReportSlice";

const CustomTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    min-width: 100px;
    max-width: 250px;
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: "10 !important",
  },
});

const ROW_TYPES = {
  HEADER: "HEADER",
  TOTAL: "TOTAL",
  GROUP_TOTAL: "GROUP_TOTAL",
  ACCOUNT: "ACCOUNT",
  GROUP: "GROUP",
  METRIC: "METRIC",
  FINAL: "FINAL",
  INITIAL: "INITIAL",
  EBITDA_HEADER: "EBITDA_HEADER",
  EBITDA_ROW: "EBITDA_ROW",
  FORMULA: "FORMULA",
};
let REVENUE_TYPE = ["Revenue", "Other Income", "Interest Income"];
const defaultKeys = [
  "name",
  "parentId",
  "subParentId",
  "rowType",
  "id",
  "type",
];

export default function ProfitAndLossTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accountingFirmId, entityId, clientSpaceId } = useParams();
  const [spreadSheetData, setSpreadSheetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isLoadingSpreadsheet, setIsLoadingSpreadsheet] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState([]);
  const [selectedSpreadSheet, setSelectedSpreadsheet] = useState(null);
  const navigate = useNavigate();
  const [reportingMonth, setReportingMonth] = useState(
    dayjs().subtract(1, "month").toISOString()?.slice(0, 7)
  );
  const { spreadsheets: spreadsheetList, isFetching: isFetchingSpreadsheet } =
    useSelector((state) => state.spreadsheet);
  const { plData, isFetching } = useSelector((state) => state.profitAndLoss);
  useEffect(() => {
    dispatch(getSpreadSheets({ accountingFirmId, entityId, search: "" }));
    return () => {
      dispatch(resetData());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (spreadsheetList.length) {
      setSpreadSheetData(spreadsheetList);
    }
  }, [spreadsheetList]);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    setIsLoadingSpreadsheet(isFetchingSpreadsheet);
  }, [isFetchingSpreadsheet]);
  const handleApplyFilters = () => {
    dispatch(
      getProfitLossData({
        entityId,
        accountingFirmId,
        spreadSheetId: selectedSpreadSheet,
        reportingMonth,
      })
    );
  };
  useEffect(() => {
    dispatch(resetData());
    let reportList = JSON.parse(localStorage.getItem("reportList"));
    let existingReport = reportList?.find((val) => val.entityId === entityId);
    if (existingReport) {
      setSelectedSpreadsheet(existingReport.spreadSheetId);
      setReportingMonth(existingReport.reportingMonth);
      dispatch(
        getProfitLossData({
          entityId,
          accountingFirmId,
          spreadSheetId: existingReport.spreadSheetId,
          reportingMonth: existingReport.reportingMonth,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleCollapsible = (id) => {
    let clone = _.cloneDeep(collapsedRows);
    if (clone.includes(id)) {
      let index = clone.indexOf(id);
      clone.splice(index, 1);
      setCollapsedRows(clone);
    } else {
      setCollapsedRows([...collapsedRows, id]);
    }
  };
  let filteredRows = plData.rows.filter((row) => {
    if (
      row.rowType === ROW_TYPES.METRIC ||
      row.rowType === ROW_TYPES.EBITDA_HEADER
    ) {
      return true;
    } else {
      if (
        collapsedRows.includes(row.parentId) ||
        collapsedRows.includes(row.subParentId)
      ) {
        return false;
      } else {
        return true;
      }
    }
  });

  let emptyRowsFilteredData = filteredRows.filter((row) => {
    if (
      row.rowType === ROW_TYPES.ACCOUNT ||
      row.rowType === ROW_TYPES.EBITDA_ROW
    ) {
      let isEmpty = true;
      Object.keys(row).forEach((key) => {
        if (!defaultKeys.includes(key)) {
          if (row[key] !== 0) {
            isEmpty = false;
          }
        }
      });
      return !isEmpty;
    } else {
      return true;
    }
  });

  const ref = useRef(null);

  const handleCreateCustomReport = () => {
    navigate(
      `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/profitAndLoss/custom`
    );
    dispatch(reset());
  };

  return (
    <div>
      <div className="budget-table">
        {downloadModalOpen && (
          <DownloadModal
            modalOpen={downloadModalOpen}
            setModalOpen={setDownloadModalOpen}
            ref={ref}
            spreadsheetList={spreadsheetList}
            selectedSpreadSheet={selectedSpreadSheet}
            reportingMonth={reportingMonth}
            plData={plData}
          />
        )}
        <Paper
          sx={{
            border: 0,
            borderTop: 0,
            borderRadius: 0,
            boxShadow: "none",
            height: "80vh",
          }}
        >
          {!isLoadingSpreadsheet && (
            <Grid container>
              <Grid item xs={12} className="mt-10 p-20">
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      fontWeight={"bold"}
                      className="header-color"
                    >
                      Profit and Loss
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Grid item className="ml-20">
                        <ButtonComponent
                          contained
                          onClick={handleCreateCustomReport}
                          startIcon={<Add />}
                          title={"Custom"}
                        />
                      </Grid>
                      <Grid item xs={2} className="ml-10">
                        <DatePicker
                          format={"MMM YYYY"}
                          style={{ width: "100%" }}
                          allowClear={false}
                          size="large"
                          value={dayjs(reportingMonth)}
                          picker="month"
                          onChange={(date, dateString) => {
                            setReportingMonth(dateString);
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          className="ml-10"
                          label="Spreadsheet"
                          size="small"
                          select
                          fullWidth
                          value={selectedSpreadSheet}
                        >
                          {spreadSheetData
                            ?.filter(
                              (data) => data.initializationType === "xero"
                            )
                            ?.map((option) => (
                              <MenuItem
                                key={option._id}
                                onClick={() => {
                                  setSelectedSpreadsheet(option._id);
                                }}
                                value={option._id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item className="ml-20">
                        <ButtonComponent
                          disabled={!selectedSpreadSheet || isLoading}
                          onClick={handleApplyFilters}
                          title={"Apply"}
                        />
                      </Grid>
                      <Grid item className="ml-10">
                        <ButtonComponent
                          className="text-normal"
                          disabled={!plData.rows.length}
                          onClick={() => {
                            setDownloadModalOpen(true);
                          }}
                          title={"Download"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isLoading && <Loading />}
              {selectedSpreadSheet &&
                plData.rows.length !== 0 &&
                !isLoading && (
                  <Grid item xs={12} className="p-20 mt-neg-20">
                    <div className="full-container">
                      <TableContainer className="spreadsheet-container">
                        <Table stickyHeader ref={ref}>
                          <TableHead>
                            <TableRow>
                              {Object.keys(plData?.columns).map((key) => {
                                return (
                                  <CustomTableCell
                                    align={key === "name" ? "left" : "right"}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      padding: 5,
                                    }}
                                    className={
                                      key === "name" ? classes.stickyHeader : ""
                                    }
                                  >
                                    {plData?.columns[key]}
                                  </CustomTableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {emptyRowsFilteredData?.map((row) => {
                              let totalRow = plData.rows.find(
                                (val) =>
                                  (row.rowType === ROW_TYPES.METRIC ||
                                  row.rowType === ROW_TYPES.EBITDA_HEADER
                                    ? val.parentId === row.id
                                    : val.subParentId === row.id) &&
                                  (row.rowType === ROW_TYPES.METRIC ||
                                    row.rowType === ROW_TYPES.GROUP ||
                                    row.rowType === ROW_TYPES.EBITDA_HEADER) &&
                                  (row.rowType === ROW_TYPES.METRIC ||
                                  row.rowType === ROW_TYPES.EBITDA_HEADER
                                    ? val.rowType === ROW_TYPES.TOTAL
                                    : val.rowType === ROW_TYPES.GROUP_TOTAL)
                              );
                              let isRevenue = REVENUE_TYPE.includes(row.type);
                              return (
                                <TableRow>
                                  {Object.keys(plData?.columns).map((key) => {
                                    let isCollapsibleRow =
                                      (row.rowType === ROW_TYPES.GROUP ||
                                        row.rowType === ROW_TYPES.METRIC ||
                                        row.rowType ===
                                          ROW_TYPES.EBITDA_HEADER) &&
                                      key === "name";
                                    let isPositive =
                                      isRevenue ||
                                      row.rowType === ROW_TYPES.FORMULA
                                        ? (collapsedRows.includes(row.id)
                                            ? totalRow[key]
                                            : row[key]) > 0
                                          ? true
                                          : false
                                        : (collapsedRows.includes(row.id)
                                            ? totalRow[key]
                                            : row[key]) < 0
                                        ? true
                                        : false;
                                    let value = collapsedRows.includes(row.id)
                                      ? key === "name"
                                        ? row[key]
                                        : totalRow?.[key]?.toLocaleString()
                                      : key !== "name" &&
                                        (row?.rowType === ROW_TYPES.ACCOUNT ||
                                          row?.rowType === ROW_TYPES.TOTAL ||
                                          row?.rowType ===
                                            ROW_TYPES.EBITDA_ROW ||
                                          row?.rowType === ROW_TYPES.FORMULA ||
                                          row?.rowType ===
                                            ROW_TYPES.GROUP_TOTAL)
                                      ? Math.abs(
                                          row[key] || 0
                                        )?.toLocaleString()
                                      : row[key] || 0;
                                    let actualValue = collapsedRows.includes(
                                      row.id
                                    )
                                      ? totalRow[key] || 0
                                      : row[key] || 0;
                                    return (
                                      <CustomTableCell
                                        className={
                                          key === "name" ? classes.sticky : ""
                                        }
                                        align={
                                          key === "name" ? "left" : "right"
                                        }
                                        style={{
                                          fontWeight:
                                            row.rowType === ROW_TYPES.ACCOUNT ||
                                            row.rowType === ROW_TYPES.INITIAL ||
                                            row.rowType === ROW_TYPES.EBITDA_ROW
                                              ? "normal"
                                              : "bold",
                                          fontSize: "16px",
                                          padding: 0,
                                          backgroundColor:
                                            row.rowType === ROW_TYPES.FORMULA
                                              ? "#EFF2F8"
                                              : "#FFFFFF",
                                          maxWidth: "50vw",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginTop: 5,
                                            marginBottom: 5,
                                            whiteSpace: "nowrap",
                                            overflow: " hidden",
                                            textOverflow: "ellipsis",
                                            display: isCollapsibleRow
                                              ? "flex"
                                              : "",
                                            justifyContent: isCollapsibleRow
                                              ? "space-around"
                                              : "",
                                            height: "20px",
                                          }}
                                        >
                                          {row.name !== " " &&
                                            (row.name !== "" ||
                                              row.rowType === "INITIAL") && (
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: " hidden",
                                                  textOverflow: "ellipsis",
                                                  marginLeft:
                                                    row.rowType ===
                                                      ROW_TYPES.ACCOUNT ||
                                                    row.rowType ===
                                                      ROW_TYPES.GROUP ||
                                                    row.rowType ===
                                                      ROW_TYPES.EBITDA_ROW ||
                                                    row.rowType ===
                                                      ROW_TYPES.GROUP_TOTAL
                                                      ? 15
                                                      : 0,

                                                  color:
                                                    (key ===
                                                      "variancePercent" ||
                                                      key === "variance") &&
                                                    actualValue !== 0
                                                      ? isPositive
                                                        ? "green"
                                                        : "red"
                                                      : "black",
                                                }}
                                              >
                                                <span>
                                                  {actualValue < 0 && "("}
                                                  {actualValue === 0 &&
                                                  (key === "variancePercent" ||
                                                    row.rowType === "INITIAL")
                                                    ? " "
                                                    : key === "name" ||
                                                      row.rowType === "INITIAL"
                                                    ? value
                                                    : Math.round(
                                                        Math.abs(actualValue)
                                                      ).toLocaleString()}

                                                  {actualValue < 0 && ")"}
                                                  {key === "variancePercent" &&
                                                    !!actualValue &&
                                                    " %"}
                                                </span>
                                                {key === "variancePercent" &&
                                                  actualValue !== 0 &&
                                                  !!actualValue && (
                                                    <span>
                                                      {isPositive && (
                                                        <ArrowDropUp fontSize="12" />
                                                      )}
                                                      {!isPositive && (
                                                        <ArrowDropDown fontSize="12" />
                                                      )}
                                                    </span>
                                                  )}
                                              </span>
                                            )}

                                          {isCollapsibleRow && (
                                            <IconButton
                                              sx={{
                                                padding: 0,
                                                marginLeft: "auto",
                                              }}
                                              onClick={() =>
                                                handleCollapsible(row.id)
                                              }
                                            >
                                              {!collapsedRows.includes(
                                                row.id
                                              ) && <KeyboardArrowDown />}
                                              {collapsedRows.includes(
                                                row.id
                                              ) && <KeyboardArrowRight />}
                                            </IconButton>
                                          )}
                                        </p>
                                      </CustomTableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                )}

              {(!selectedSpreadSheet || !plData.rows.length) && !isLoading && (
                <Grid item xs={12}>
                  <div style={{ height: "400px", textAlign: "center" }}>
                    <Typography style={{ marginTop: "200px" }}>
                      Please select reporting month and the spreadsheet to
                      generate profit and loss table
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
          {isFetchingSpreadsheet && <Loading />}
        </Paper>
      </div>
    </div>
  );
}
