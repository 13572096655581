import React, { useEffect, useState } from "react";
import TableListCard from "../../../../components/TableListCard/TableListCard";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReport,
  getReports,
} from "../../../../redux/CustomReport/CustomReportSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingIndicator/Loading";
import NoDataView from "../../../../components/NoDataView/NoDataView";
import { ButtonComponent } from "../../../../components/Button/Button";
import { Delete, Visibility } from "@mui/icons-material";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import { reset } from "../../../../redux/ProfitandLoss/ProfitandLossSlice";

const CustomReportTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { clientSpaceId, entityId, accountingFirmId } = useParams();
  // eslint-disable-next-line
  const [search, setSearch] = useState("");
  const [deleteconfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { reports, isFetching } = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(getReports({ entityId, accountingFirmId, search }));
    // eslint-disable-next-line
  }, []);
  const handleDelete = () => {
    dispatch(deleteReport({ reportId: selectedId, accountingFirmId }));
    setSelectedId(null);
  };
  return (
    <div>
      <ConfirmModal
        message="Are you sure you want to delete the custom report?"
        declineFunction={() => setDeleteConfirmModalOpen(false)}
        confirmFunction={() => {
          setDeleteConfirmModalOpen(false);
          handleDelete();
        }}
        setModalOpen={setDeleteConfirmModalOpen}
        modalOpen={deleteconfirmModalOpen}
        confirmText="Yes"
        declineText="No"
      />
      <TableListCard title={"Custom Reoprts"}>
        {!isFetching && reports.length !== 0 && (
          <div style={{ padding: "20px" }}>
            <TableContainer
              style={{
                height: "70vh",
                overflowY: "auto",
                maxHeight: "60vh",
                maxWidth: {
                  xs: 260,
                  sm: 290,
                  md: 580,
                  lg: "inherit",
                },
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={"bold"}>S.N</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Report Name</Typography>
                    </TableCell>
                    <TableCell width={"30%"}>
                      <Typography fontWeight={"bold"}>
                        Report Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Created By</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"}>Created At</Typography>
                    </TableCell>
                    <TableCell width={"20%"}>
                      <Typography fontWeight={"bold"}>Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell
                          style={{ maxWidth: 200, wordWrap: "break-word" }}
                        >
                          {data.description}
                        </TableCell>
                        <TableCell>
                          {data.createdBy.first_name +
                            " " +
                            data.createdBy.last_name}
                        </TableCell>
                        <TableCell>{data.createdAt.substring(0, 10)}</TableCell>
                        <TableCell>
                          <Grid>
                            <ButtonComponent
                              size="small"
                              id={"more-" + data._id}
                              contained
                              title={"View"}
                              className={"mr-10"}
                              startIcon={<Visibility />}
                              onClick={() => {
                                dispatch(reset());
                                navigate(
                                  `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/profitAndLoss/custom/${data._id}`
                                );
                              }}
                            />
                            <ButtonComponent
                              onClick={() => {
                                setSelectedId(data._id);
                                setDeleteConfirmModalOpen(true);
                              }}
                              startIcon={<Delete />}
                              size="small"
                              id={"more-" + data._id}
                              title={"Delete"}
                            />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {isFetching && <Loading />}
        {!isFetching && reports.length === 0 && search !== "" && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            Search Results empty
          </div>
        )}
        {!isFetching && reports.length === 0 && search === "" && (
          <NoDataView
            hintText="Sorry, you haven't added any custom reports"
            buttonText="Add new custom report"
            height="60vh"
            showButton={false}
            // addFunction={() => setNewSheetOpen(true)}
          />
        )}
      </TableListCard>
    </div>
  );
};

export default CustomReportTab;
